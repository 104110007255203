<template>
<div class="">
  <NavBar
   title="服务"
   left-arrow
   @click-left="$router.go(-1)"
  />
  <Card v-for="(idx,index) in packagelist"  :key="index" 
    :title="idx.package.title"
    :thumb="require('@/assets/healthpackages/a.jpg')"
  >
   <template #desc>
     <div class="d_desc">
        {{idx.package.des}}
    </div>
  </template>
  <template #tags>
    <tag plain type="primary"  >服务中：{{idx.start_date}}~{{idx.end_date}}</tag>
  </template>
  </Card>
   <empty v-if="nodata" description="您还没有订购套餐" />
</div>
</template>

<script>
import { Empty,Card,Tag } from 'vant';
import {activatePackages} from '@/api/healthpackages';
import {getUser} from "@/api/user";
export default {
  name: 'packagelist',
  components: {
     Empty,Card,Tag
  },
  data(){
    return {
      user:{
        userName:'',
        nickName:'',
        idCard:'',
        phone:'',
        gender:'1',
        birthday:'',
        id:'',
        pid:''
      },
        packagelist:[],
        nodata:false,
        packageMap:this.$store.state.hmpackages.packageMap
    };
  },
  methods:{
     showPackage(idx){
       this.$router.push({path: '/healthmanage/orderdetail/'+idx.orderNumber});
     }
  },
   created() {
     let query = this.$route.query;
     let patientid=query.patientid;
     getUser({patientid:patientid}).then((res) => {
       console.info(res.data);

       if(res.data){
         this.$store.commit('updateOpenid',res.data.openid);
         this.$store.commit('updatePid',res.data.id);
         this.$store.commit('updateBaseInfo',res.data);
         // if(res.data.name !=''){
         //   this.substatus = true
         // }
         this.user.id=res.data.id;
         this.user.name=res.data.name;
         this.user.birthday=res.data.birthday;
         // this.user.idCard=response.data.idCard;
         this.user.telephone=res.data.telephone;
         this.user.gender=res.data.gender+'';
         this.user.address=res.data.address;
         this.user.detail_address=res.data.detail_address;
         // this.initBaseInfo();
         // this.user_empty = false

         // this.initData();
         activatePackages({patientid:patientid}).then((response) => {
           console.log(response)
           if(response.code==200 ){
             this.packagelist=response.data;
             if(this.packagelist.length==0){
               this.nodata=true;
             }
           }
         });

       }
     });

  },
}
</script>

<style scoped>
.is_completed{
  color: aqua;
}
.is_uncompleted{
  color:red;
}
.is_cancel{
  color:gray;
}
.d_desc{
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
